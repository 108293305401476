















































import { CancellationInfo, PagedResults, reasonToLabel } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({})
export default class CancellationTable extends Mixins(ComponentHelper) {
  @Prop({ required: true }) data!: PagedResults<CancellationInfo>

  reasonToLabel = reasonToLabel
}

