import { render, staticRenderFns } from "./Cancellations.vue?vue&type=template&id=487c2584&scoped=true&"
import script from "./Cancellations.vue?vue&type=script&lang=ts&"
export * from "./Cancellations.vue?vue&type=script&lang=ts&"
import style0 from "./Cancellations.vue?vue&type=style&index=0&id=487c2584&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "487c2584",
  null
  
)

export default component.exports