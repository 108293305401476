










import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart as ChartJS } from 'chart.js'
import PieChart from './graphs/PieChart.vue'

ChartJS.register(ChartDataLabels)

@Component({
  components: {
    PieChart
  }
})
export default class CancellationPieChart extends Mixins(ComponentHelper) {
  @Prop({ required: true }) data!: any[]

  options = {
    plugins: {
      legend: {
        position: 'bottom'
      },
      datalabels: {
        backgroundColor (context) {
          return context.dataset.backgroundColor
        },
        borderColor: 'white',
        borderRadius: 25,
        borderWidth: 2,
        color: 'black',
        font: {
          weight: 'bold',
          size: 10
        }
      }
    },
    aspectRatio: 4 / 3,
    cutoutPercentage: 32,
    layout: {
      padding: 32
    },
    elements: {
      line: {
        fill: false
      },
      point: {
        hoverRadius: 7,
        radius: 5
      }
    }
  }
}
