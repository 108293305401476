




import { Pie } from 'vue-chartjs/legacy'
import 'chart.js/auto'
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'PieChart',
  components: { Pie }
})
export default class PieChart extends Vue {
  @Prop({ default: () => [] }) data!: any
  @Prop({ default: () => {} }) options!: any
  @Prop({ default: 'pie-chart', required: true }) chartId!: string
}

